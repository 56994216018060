import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { pushHistoryActions } from '../../actions/entities/push-history.actions';
import { AllBwicsTabList } from '../allBWICS/AllBwicsTabList';
import { AppState } from '../../types/state/AppState';
import { bwicHistoryActions, controlPanelActions, searchSecuritiesActions, sellerBuysideActions } from '../../actions';
import { TableColumnStickType } from '../bidding/common/table/types/TableColumnStickType';
import { FilterButton, FilterClear, FilterPanel, FilterSection, SecuritiesSearchInput } from '../filters';
import { EmptyPlaceholder, Preloader, Relative } from '../common';
import { DateFilterOption } from '../../types/filters/DateFilterOption';
import { DateRange } from '../../types/filters/DateRange';
import { FilterOption } from '../../types/filters/FilterOption';
import { Rating } from '../../types/enums/Rating';
import { Table } from '../bidding/common/table';
import { ControlPanel } from '../bidding/common/ControlPanel';
import { SellerBuysideSearchResult } from '../../types/bid-as-dealer/SellerBuysideSearchResult';
import { RequestSettlementAgentAgreementBanner } from '../request-settlement-agent-agreement/RequestSettlementAgentAgreementBanner';
import { sellerBuySideColumns } from './SellerBuysideColumns';
import { arrayUtils, isRequesting, isRequestSuccess } from '../../utils';
import IconSVG from '../../styles/svg-icons';
import { AllBwicsTab } from '../allBWICS/AllBwicsTab';
import { isActiveTrade, TradeStatus } from '../../types/trades/TradeStatus';
import { BwicStatus } from '../../types/enums/BwicStatus';
import { SellerBuysideConfirmPopup } from './SellerBuysideConfirmPopup';
import { SellerBuysideRouteLeavingGuard } from './SellerBuysideRouteLeavingGuard';
import { BwicMonitorAlertsPreferences } from '../profile/tabs/email-preferences/tabs/bwic-monitor/BwicMonitorAlertsPreferences';
import { FilterSelect } from '../common/filters/FilterSelect';
import { FilterRange } from '../common/filters/FilterRange';
import { DateRangeFilter } from '../common/filters/FilterDateRange/DateRangeFilter';
import { bwicDateFilterOptions, constants, roles } from '../../constants';
import { FilterBoolean } from '../common/filters/FilterBoolean';
import { SettlementAgreementStatus } from '../../types/bid-as-dealer/SettlementAgreementStatus';
import { BwicProcessType } from '../../types/models/Process';
import { OpenBiddingStatus } from '../../types/enums/OpenBiddingStatus';
import { liveBiddingUtils } from '../../utils/live-bidding.utils';
import { RecipientType, useBiddingCompletedNotification } from '../../effects/useBiddingCompletedNotification';
import { getCanBidFlag } from './getCanBidFlag';
import { useAgreement } from './useAgreement';
import { user } from '../../user';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { SortDropdown } from '../common/SortDropdown';
import { BwicMonitorSortByOptions, bwicMonitorSortByOptionsText } from '../../types/state/AllBwicsState';
import { ActionBlocker } from '../access/ActionBlocker';
import { FeatureButton } from '../access/FeatureButton';
import { BlockedFeatureContent } from '../access/BlockedFeatureText';
import { SellerBuysideSummaryRow } from './SellerBuysideSummaryRow';
import { appConfig } from '../../app-config';
import { useAppDispatch } from '../../effects/useAppDispatch';

export function SellerBuyside() {
    const dispatch = useAppDispatch();
    const securityDetailsPositionId: number | undefined = useSelector((state: AppState) => state.securityDetails.security?.id);
    const bwicLogPositionId: number | undefined = useSelector((state: AppState) => state.bwicLog.security?.id);
    const pxTalkListPositionId: number | undefined = useSelector((state: AppState) => state.pxTalkList.positionId);
    const rulesPositionId: number | undefined = useSelector((state: AppState) => state.rules.positionId);
    const historyPositionId: number | undefined = useSelector((state: AppState) => state.bwicHistory.positionId);
    const searchTermItems: any[] = useSelector((state: AppState) => state.searchSecurities.searchTermItems || []);
    const filter = useSelector((s: AppState) => s.sellerBuyside.filter);
    const sortBy = useSelector((s: AppState) => s.sellerBuyside.sortBy);
    const initialFilter = useSelector((s: AppState) => s.sellerBuyside.initialFilter);
    const initRequestStatus = useSelector((s: AppState) => s.sellerBuyside.initRequestStatus);
    const lastAppliedFilter = useSelector((s: AppState) => s.sellerBuyside.lastAppliedFilter);
    const isLoading = useSelector((s: AppState) => s.sellerBuyside.isLoading);
    const dataItems = useSelector((s: AppState) => s.sellerBuyside.dataItems);
    const totalRecordNumber = useSelector((s: AppState) => s.sellerBuyside.totalRecordNumber);
    const currentPageNumber = useSelector((s: AppState) => s.sellerBuyside.currentPageNumber);
    const requestStateExportBidRequests = useSelector((s: AppState) => s.sellerBuyside.requestStateExportBidRequests);
    const advancedFiltersBlocked = useSelector((s: AppState) => s.sellerBuyside.advancedFiltersBlocked);
    const { agreement, requestState: agreementsRequestState } = useAgreement();
    const isConfirmedAgreementExist = agreement?.agreementStatus === SettlementAgreementStatus.confirmed;
    const biddingDataItems = dataItems.filter(i => i.bwic.status === BwicStatus.bidding);
    const biddingCompletedNotificationBwics = [...arrayUtils
        .groupBy(biddingDataItems, i => i.bwic.referenceName)
        .entries()]
        .map(([, dataItems]) => ({
            ...dataItems[0].bwic,
            securities: dataItems.map(i => i.position)
        }))


    useBiddingCompletedNotification(
        RecipientType.Bidder,
        ...biddingCompletedNotificationBwics
    );

    const activePositionId: number | undefined =
        securityDetailsPositionId ||
        bwicLogPositionId ||
        pxTalkListPositionId ||
        rulesPositionId ||
        historyPositionId;
    const activeDataItem = activePositionId ? dataItems.find(i => i.position.id === activePositionId) : undefined;
    const isFiltersDisabled = isLoading;
    const isFilterChanged = useMemo(() => !isEqual(initialFilter, filter) || !!searchTermItems.length, [initialFilter, filter, searchTermItems]);

    const isInitialFilterApplied = useMemo(() => isEqual(initialFilter, lastAppliedFilter), [initialFilter, lastAppliedFilter]);

    const isNoData = !advancedFiltersBlocked && !dataItems.length && !isLoading && isRequestSuccess(initRequestStatus);

    useEffect(() => {
        dispatch(sellerBuysideActions.init());
        return () => {
            dispatch(sellerBuysideActions.reset());
            dispatch(pushHistoryActions.reset());
            dispatch(controlPanelActions.hide());
        }
    }, [dispatch]);


    const handleDateFilterChange = (dateOption: DateFilterOption) =>
        dispatch(sellerBuysideActions.dateFilterChange(dateOption));

    const handleCustomDateFilterChange = (dateRange: DateRange) =>
        dispatch(sellerBuysideActions.customDateFilterChange(dateRange));

    const handleStatusFilterChange = (option: FilterOption) =>
        dispatch(sellerBuysideActions.bwicStatusFilterChange(option.value as number, !option.selected));

    const handleToggleAllStatusFilterChange = (selected: boolean) => {
        filter.bwicStatuses.forEach(option => {
            dispatch(sellerBuysideActions.bwicStatusFilterChange(option.value as number, selected));
        })
    }

    const handleCurrencyChange = (option: FilterOption) =>
        dispatch(sellerBuysideActions.currencyFilterChange(option.value as string, !option.selected));

    const handleToggleAllCurrencyFilterChange = (selected: boolean) => {
        filter.currencies.forEach(option => {
            dispatch(sellerBuysideActions.currencyFilterChange(option.value as string, selected));
        })
    }

    const handleRatingFilterChange = (option: FilterOption) =>
        dispatch(sellerBuysideActions.ratingFilterChange(option.value as Rating, !option.selected));

    const handleToggleAllRatingFilterChange = (selected: boolean) => {
        filter.ratings.forEach(option => {
            dispatch(sellerBuysideActions.ratingFilterChange(option.value as Rating, selected));
        })
    }

    const handleFilterSizeChange = (size: { min?: number | string, max?: number | string }) =>
        dispatch(sellerBuysideActions.sizeFilterChange(size))

    // temporarily hidden
    // const handleTypeFilterChange = (type: FilterOption) =>
    //     dispatch(sellerBuysideActions.typeFilterChange(type.value as BwicType, !type.selected));

    const handleApplyFilter = () => {
        dispatch(sellerBuysideActions.pagingReset());
        dispatch(sellerBuysideActions.filterApply(true));
    };

    const handleClearFilter = () => {
        dispatch(sellerBuysideActions.pagingReset());
        dispatch(sellerBuysideActions.filterReset());
        dispatch(sellerBuysideActions.filterApply(true));
    };

    const handleNextPageRequest = () => {
        if (!isLoading) {
            dispatch(sellerBuysideActions.loadNextPage());
            dispatch(sellerBuysideActions.filterApply());
        }
    };

    const handleRenderGroupByRow = (current: SellerBuysideSearchResult, prev?: SellerBuysideSearchResult, columnType?: TableColumnStickType) => {
        const currentDate = moment.utc(current.bwic.bidsDueDateUtc);
        if (prev == null || (!moment.utc(prev.bwic.bidsDueDateUtc).isSame(currentDate, 'day') && columnType)) {
            if (columnType === TableColumnStickType.Middle) {
                return currentDate.isSame(new Date(), 'day')
                    ? `Today ${currentDate.format(constants.dateFormatDoubleDay)}`
                    : currentDate.format('dddd, MMM DD, YYYY');
            }

            return <></>;
        }
        return null;
    }

    const createRowContext = (item: SellerBuysideSearchResult) => {
        const isLiveBiddingStage2 =
            item.bwic.process.type === BwicProcessType.Live &&
            item.bwic.process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended;
        const liveBiddingStage2SubmissionDeadline = isLiveBiddingStage2
            ? liveBiddingUtils.calculateStage2SubmissionDeadline(item.bwic.bidsDueDateUtc, item.bwic.process, item.position.latestBidSubmission)
            : undefined;
        const liveBiddingStage2Expired = isLiveBiddingStage2 && moment.utc().diff(liveBiddingStage2SubmissionDeadline) > 0;

        return {
            liveBiddingStage2SubmissionDeadline,
            liveBiddingStage2Expired
        };
    }

    const createRowClassName = (
        entity: SellerBuysideSearchResult,
        context: { liveBiddingStage2Expired: boolean }) => {
        if (activeDataItem?.position.id === entity.position.id) {
            return 'active';
        }
        if (entity.position.isTradedAway ||
            entity.bwic.isColorDistribution ||
            (entity.bwic.status === BwicStatus.finished && !isActiveTrade(entity.position.trade)) ||
            entity.bwic.status === BwicStatus.canceled ||
            entity.position.trade?.status === TradeStatus.rejected) {
            return 'tradedaway';
        }
        if (entity.position.trade && entity.position.trade.status === TradeStatus.affirmed) {
            return 'affirmed';
        }
        if (entity.position.trade?.status === TradeStatus.pending) {
            return 'pending-trade';
        }
        if (
            !getCanBidFlag(entity, agreement) ||
            context.liveBiddingStage2Expired ||
            !user.hasRoles(roles.SellerTrader)) {
            return 'inactive';
        }

        return '';
    }

    const isApplyButtonDisabled =
        !isRequestSuccess(initRequestStatus) ||
        !isRequestSuccess(agreementsRequestState) ||
        isLoading ||
        isEqual(filter, lastAppliedFilter) ||
        Number(filter.size?.max) < Number(filter.size?.min)

    return (
        <div className="container seller-buyside-container">
            <div className="sub-header">
                <div className="sub-header-row type01 flex-row">
                    <AllBwicsTabList activeTab={AllBwicsTab.Buy} />
                    <Relative>
                        <SecuritiesSearchInput
                            onSearch={() => dispatch(sellerBuysideActions.filterApply(false, true))}
                            disabled={isLoading || (!dataItems.length && !searchTermItems.length) || isFiltersDisabled}
                        />
                    </Relative>
                    <div className="flex-item-right controls">
                        <BwicMonitorAlertsPreferences />
                        <Preloader
                            small={true}
                            fullScreen={false}
                            inProgress={isRequesting(requestStateExportBidRequests)}
                            text="Exporting…"
                        >
                            <ActionBlocker feature={SubscriptionFeature.exportAllBwics}>
                                <FeatureButton
                                    className="btn btn-link"
                                    disabled={!dataItems.length || isLoading}
                                    onClick={() => dispatch(sellerBuysideActions.exportBidRequestsRequest())}
                                >
                                    <IconSVG name="export" width={16} height={16} /> Export All
                                </FeatureButton>
                            </ActionBlocker>
                        </Preloader>
                    </div>
                </div>
                <div className="sub-header-row type02 flex-row">
                    <FilterPanel>
                        <ActionBlocker feature={SubscriptionFeature.BwicMonitorAvancedFilters}>
                            {blocked =>
                                <DateRangeFilter
                                    title="Date"
                                    customClassName="wide-range-date"
                                    disabled={isFiltersDisabled || blocked}
                                    selectedDateOption={filter.selectedDateOption}
                                    customDateRange={filter.customDateRange}
                                    onSelectedDateChange={handleDateFilterChange}
                                    onCustomDateChange={handleCustomDateFilterChange}
                                    onReset={() => dispatch(sellerBuysideActions.dateFilterChange(bwicDateFilterOptions.unspecified))}
                                    filter={filter}
                                    lastAppliedFilter={lastAppliedFilter || filter}
                                    initialFilter={initialFilter}
                                />
                            }
                        </ActionBlocker>
                        <FilterRange
                            defaultExpanded={false}
                            disabled={isFiltersDisabled}
                            isApplied={!!(filter.size.min || filter.size.max) && isEqual(lastAppliedFilter?.size, filter.size)}
                            title="Size (MM)"
                            options={{ from: filter.size.min, to: filter.size.max, minValue: 0, maxValue: 999.999 }}
                            onClearAll={() => handleFilterSizeChange({ min: '', max: '' })}
                            onChangeRange={range => handleFilterSizeChange({ min: range.from, max: range.to })}
                            mask={{
                                allowDecimal: true,
                                integerLimit: 3,
                                decimalLimit: 3,
                                prefix: '',
                                suffix: ''
                            }}
                        />
                        <FilterSelect
                            title="Status"
                            multiply={true}
                            disabled={isFiltersDisabled}
                            isApplied={isEqual(lastAppliedFilter?.bwicStatuses, filter.bwicStatuses)}
                            options={filter.bwicStatuses}
                            onChangeItemSelection={handleStatusFilterChange}
                            onSelectAll={() => handleToggleAllStatusFilterChange(true)}
                            onClearAll={() => handleToggleAllStatusFilterChange(false)}
                        />
                        <FilterSelect
                            title="Ccy"
                            multiply={true}
                            disabled={isFiltersDisabled}
                            isApplied={isEqual(lastAppliedFilter?.currencies, filter.currencies)}
                            options={filter.currencies}
                            onChangeItemSelection={handleCurrencyChange}
                            onSelectAll={() => handleToggleAllCurrencyFilterChange(true)}
                            onClearAll={() => handleToggleAllCurrencyFilterChange(false)}
                        />
                        <FilterSelect
                            title="Rtg"
                            multiply={true}
                            disabled={isFiltersDisabled}
                            isApplied={isEqual(lastAppliedFilter?.ratings, filter.ratings)}
                            options={filter.ratings}
                            onChangeItemSelection={handleRatingFilterChange}
                            onSelectAll={() => handleToggleAllRatingFilterChange(true)}
                            onClearAll={() => handleToggleAllRatingFilterChange(false)}
                        />
                        {/*temporarily hidden*/}
                        {/*<FilterSection title="Type">*/}
                        {/*    <FilterMultiPicker*/}
                        {/*        disabled={isFiltersDisabled}*/}
                        {/*        sourceItems={filter.types}*/}
                        {/*        onChangeItemSelection={handleTypeFilterChange}*/}
                        {/*    />*/}
                        {/*</FilterSection>*/}
                        <FilterBoolean
                            value={filter.submittedBids}
                            disabled={isFiltersDisabled || !isConfirmedAgreementExist}
                            isApplied={isEqual(lastAppliedFilter?.submittedBids, filter.submittedBids)}
                            title="Submitted Bids"
                            onChangeItemSelection={(option) => dispatch(sellerBuysideActions.submittedBidsFilterChange(option as unknown as boolean | null))}
                            onClearAll={() => dispatch(sellerBuysideActions.submittedBidsFilterChange(null))}
                        />
                        <FilterSection>
                            <FilterButton
                                caption="Apply"
                                disabled={isApplyButtonDisabled}
                                onClick={handleApplyFilter}
                            />
                        </FilterSection>
                        <FilterSection>
                            <FilterClear
                                isShown={
                                    isRequestSuccess(initRequestStatus) &&
                                    isRequestSuccess(agreementsRequestState) &&
                                    !isLoading &&
                                    !isEqual(initialFilter, filter)
                                }
                                onClick={handleClearFilter}
                            />
                        </FilterSection>
                    </FilterPanel>
                    <div className="margin-l-a">
                        <ActionBlocker feature={SubscriptionFeature.BwicMonitorSorting}>
                            {
                                blocked =>
                                    <SortDropdown
                                        disabled={!dataItems.length || isLoading || blocked}
                                        items={Object.values(BwicMonitorSortByOptions).map(value => ({
                                            value,
                                            text: bwicMonitorSortByOptionsText[value],
                                            selected: sortBy === value,
                                        }))}
                                        onChange={(sortBy) => dispatch(sellerBuysideActions.sortChanged(sortBy as BwicMonitorSortByOptions))}
                                    />
                            }
                        </ActionBlocker>
                    </div>
                </div>
                <RequestSettlementAgentAgreementBanner />
            </div>
            {appConfig.isSummaryRowHidden ? null: <SellerBuysideSummaryRow />}
            <div className="container-bwics container-flex container-tabs">
                <div className="seller-buyside-wrapper flex-row flex-row-full-size position-relative">
                    <Preloader inProgress={isRequesting(initRequestStatus) || (isLoading && currentPageNumber === 1)}>
                        {advancedFiltersBlocked && <BlockedFeatureContent />}
                        {isNoData && (
                            <EmptyPlaceholder
                                onResetClick={isFilterChanged ? () => {
                                    handleClearFilter();
                                    dispatch(searchSecuritiesActions.reset());
                                } : undefined}
                                text={
                                    !isInitialFilterApplied ? undefined : (
                                        <>There are no securities available for DirectBidding&#8482;</>
                                    )
                                }
                            />
                        )}
                        {
                            !advancedFiltersBlocked &&
                            !!dataItems.length &&
                            <>
                                <Table
                                    sticky={true}
                                    className="seller-buyside-content bwic-monitor"
                                    columns={sellerBuySideColumns()}
                                    dataItems={dataItems}
                                    infiniteScrollEnabled={dataItems.length < totalRecordNumber}
                                    onNextPageRequest={handleNextPageRequest}
                                    createSecurityCustomArgs={createRowContext}
                                    createSecurityCustomClassName={createRowClassName}
                                    createRowCustomKey={(e: SellerBuysideSearchResult) => e.position.id}
                                    renderGroupByRow={handleRenderGroupByRow}
                                />
                                {
                                    activeDataItem &&
                                    <ControlPanel
                                        bwic={activeDataItem.bwic}
                                        position={activeDataItem.position}
                                        accessDealDocumentsFeature={null}
                                        onHistoryActive={() => {
                                            dispatch(bwicHistoryActions.loadHistory(
                                                activeDataItem.bwic.referenceName,
                                                activeDataItem.position.id
                                            ))
                                        }}
                                    />
                                }
                            </>
                        }
                        <SellerBuysideConfirmPopup />
                        <SellerBuysideRouteLeavingGuard />
                    </Preloader>
                </div>
            </div>
        </div>
    );
}
